import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const StyledSection = styled.section`
  & {
    ${tw`text-white overflow-hidden relative md:flex flex-nowrap items-center justify-between`}
    ${({ backgroundColor }) => backgroundColor === 'bg-navy' && tw`bg-navy`}
  ${({ backgroundColor }) => backgroundColor === 'bg-stm' && tw`bg-stm`}
  ${({ height }) =>
      height === 'full' &&
      css`
        min-height: 100vh;
        @media (max-width: 768px) {
          min-height: 80vh;
        }
      `}
  }
  ${({ height }) =>
    height === 'normal' &&
    css`
      min-height: 80vh;
    `}

  & h1 {
    ${tw`pb-8`}
    text-shadow:0px 0px 50px rgba(0,0,0,0.3)
  }
`

const ImageDotsDiv = styled.div`
  & {
    position: relative;
  }
  & > div {
    /* max-width: 600px; */
  }
  @media (max-height: 900px) {
    .gatsby-image-wrapper img {
      width: 60% !important;
    }
  }
  ${({ slug }) =>
    slug === 'home' &&
    `
      &::before {
        position: absolute;
        content: ' ';
        border-radius: 50%;
        left: 35%;
        top: 30%;
        width: 16px;
        height: 16px;
        background-color: white;
        z-index: 10;
        margin-left: 5px;
        margin-top: 5px;
      }
      &::after {
        position: absolute;
        content: ' ';
        border-radius: 50%;
        left: 35%;
        top: 30%;
        width: 26px;
        height: 26px;
        background-color: none;
        border: 2px solid white;
      }
    `}
`

export default function Hero(props) {
  const {
    image,
    backgroundImage,
    backgroundColor,
    heading,
    subHeading,
    slug,
    height,
    backgroundPosition,
  } = props.content

  const heroImage = getImage(
    image[0]?.localFile?.childImageSharp?.gatsbyImageData
  )
  const bgImage = getImage(
    backgroundImage[0]?.localFile?.childImageSharp?.gatsbyImageData
  )

  return (
    <StyledSection
      backgroundColor={backgroundColor}
      height={height}
      className="hero mm-px-dynamic mm-py-dynamic"
      css={tw`pt-44`}
    >
      {bgImage && (
        <GatsbyImage
          image={bgImage}
          alt={backgroundImage[0]?.title}
          css={tw`absolute w-full h-full left-0 top-0`}
          objectPosition={backgroundPosition === 'center bottom'? '50% 100%' : ''}
        />
      )}
      <div css={tw`z-10 relative`}>
        <div
          className="heroSubHeading"
          css={[
            tw`uppercase border-b-4 border-white pb-1 inline-flex mb-2 font-bold`,
            css`
              line-height: 0.5rem;
            `,
          ]}
          dangerouslySetInnerHTML={{ __html: subHeading }}
        />

        <div dangerouslySetInnerHTML={{ __html: heading }} css={tw`max-w-md`} />
      </div>
      <ImageDotsDiv slug={slug} css={tw`z-10 flex flex-1 justify-center`}>
        {heroImage && <GatsbyImage image={heroImage} alt={image[0]?.title} />}
      </ImageDotsDiv>
    </StyledSection>
  )
}
